@font-face {
  font-family: 'AvertaStd';
  src: url('./webfonts/AvertaStd-ExtrathinItalic.ttf');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvertaStd';
  src: url('./webfonts/AvertaStd-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvertaStd';
  src: url('./webfonts/AvertaStd-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvertaStd';
  src: url('./webfonts/AvertaStd-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvertaStd';
  src: url('./webfonts/AvertaStd-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvertaStd';
  src: url('./webfonts/AvertaStd-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvertaStd';
  src: url('./webfonts/AvertaStd-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvertaStd';
  src: url('./webfonts/AvertaStd-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvertaStd';
  src: url('./webfonts/AvertaStd-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}


:root {
  --piccolo: 78 70 180;
  /* #4e46b4 */
  --hit: 36 156 186;
  /* #40a69f */
  --beerus: 235 235 235;
  /* #ebebeb */
  --gohan: 255 255 255;
  /* #ffffff */
  --goten: 255 255 255;
  /* #ffffff */
  --goku: 245 245 245;
  /* #f5f5f5 */
  --bulma: 0 0 0;
  /* #000000 */
  --trunks: 153 156 160;
  /* #999ca0 */
  --popo: 0 0 0;
  /* #000000 */
  --jiren: 78 70 180 / 0.12;
  /* #4e46b4 */
  --heles: 0 0 0 / 0.04;
  /* #000000 */
  --zeno: 0 0 0 / 0.56;
  /* #000000 */

  /* support-colors  */
  --krillin: 255 179 25;
  /* #ff9800 */
  --krillin-60: 255 179 25 / 0.56;
  /* #ff9800 */
  --krillin-10: 255 179 25 / 0.08;
  /* #ff9800 */

  --chichi: 255 78 100;
  /* #ff4e64 */
  --chichi-60: 255 78 100 / 0.56;
  /* #ff4e64 */
  --chichi-10: 255 78 100 / 0.08;
  /* #ff4e64 */

  --roshi: 74 209 95;
  /* #2e7d32 */
  --roshi-60: 74 209 95 / 0.56;
  /* #2e7d32 */
  --roshi-10: 74 209 95 / 0.08;
  /* #2e7d32 */

  --dodoria: 211 48 48;
  /* #d33030 */
  --dodoria-60: 211 48 48 / 0.56;
  /* #d33030 */
  --dodoria-10: 211 48 48 / 0.08;
  /* #d33030 */

  --cell: 149 241 213;
  /* #95f1d5 */
  --cell-60: 149 241 213 / 0.56;
  /* #95f1d5 */
  --cell-10: 149 241 213 / 0.08;
  /* #95f1d5 */

  --raditz: 179 128 74;
  /* #b3804a */
  --raditz-60: 179 128 74 / 0.56;
  /* #b3804a */
  --raditz-10: 179 128 74 / 0.08;
  /* #b3804a */

  --whis: 52 72 240;
  /* #3448f0 */
  --whis-60: 52 72 240 / 0.56;
  /* #3448f0 */
  --whis-10: 52 72 240 / 0.08;
  /* #3448f0 */

  --frieza: 92 51 207;
  /* #5c33cf */
  --frieza-60: 92 51 207 / 0.56;
  /* #5c33cf */
  --frieza-10: 92 51 207 / 0.08;
  /* #5c33cf */

  --nappa: 114 85 80;
  /* #725550 */
  --nappa-60: 114 85 80 / 0.56;
  /* #725550 */
  --nappa-10: 114 85 80 / 0.08;
  /* #725550 */

  /* border-radius */
  --radius-i-xs: 0.25rem;
  /* 4px */
  --radius-i-sm: 0.5rem;
  /* 8px */
  --radius-i-md: 0.75rem;
  /* 12px */
  --radius-s-xs: 0.25rem;
  /* 4px */
  --radius-s-sm: 0.5rem;
  /* 8px */
  --radius-s-md: 0.75rem;
  /* 12px */
  --radius-s-lg: 1rem;
  /* 16px */

  /* border-width */
  --border-width: 0.0625rem;
  /* 1px */
  --border-i-width: 0.125rem;
  /* 2px */

  /* opacity */
  --opacity-moon: 0.32;

  /* fonts */
  --averta: "AvertaStd";

  /* box-shadow */
  --shadow-xs: 0 4px 12px -6px rgb(0 0 0 / 0.06);
  --shadow-sm: 0 6px 6px -6px rgb(0 0 0 / 0.16), 0 0 1px rgb(0 0 0 / 0.4);
  --shadow-md: 0 12px 12px -6px rgb(0 0 0 / 0.16), 0 0 1px rgb(0 0 0 / 0.4);
  --shadow-lg: 0 8px 24px -6px rgb(0 0 0 / 0.16), 0 0 1px rgb(0 0 0 / 0.4);
  --shadow-xl: 0 32px 32px -8px rgb(0 0 0 / 0.08),
    0 0 32px -8px rgb(0 0 0 / 0.12), 0 0 1px rgb(0 0 0 / 0.2);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
  @apply bg-slate-100
}

.coustomstyl tr td:nth-of-type(1) {
  border: 1px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.coustomstyl tr td:nth-last-child(1) {
  border: 1px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.admintable tr td:nth-last-child(1) {
  border: 1px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.admintable tr td:nth-of-type(1) {
  border: 1px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.requesttable {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 365px);
}

.requesttable::-webkit-scrollbar {
  width: 5px;
}

.requesttable::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the thumb (the draggable part) */
  border-radius: 24px;
}

.prefrencesbox {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 320px);
}

.prefrencesbox::-webkit-scrollbar {
  width: 5px;
}

.prefrencesbox::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the thumb (the draggable part) */
  border-radius: 24px;
}

/* .requesttable::-webkit-scrollbar {
  display: none;
} */
.branchtable {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 345px);
}

.branchtable::-webkit-scrollbar {
  width: 5px;
}

.branchtable::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the thumb (the draggable part) */
  border-radius: 24px;
}

/* .emloyetable tr:nth-last-child(1) {
  padding-bottom: 90px;
} */
.emloyetable {
  overflow-y: scroll;
  overflow-x: hidden;
}

.emloyetable::-webkit-scrollbar {
  width: 8px;
}

.emloyetable::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the thumb (the draggable part) */
  border-radius: 24px;
}

.viewdrafts {
  min-width: 600px;
  min-height: 593px;
}

.viewdrftcontent {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 480px;
}

.viewdrftcontent::-webkit-scrollbar {
  width: 5px;
}

.viewdrftcontent::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the thumb (the draggable part) */
  border-radius: 24px;
}

.tableResponsive {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 340px);
}

.tableResponsive::-webkit-scrollbar {
  width: 5px;
}

.tableResponsive::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the thumb (the draggable part) */
  border-radius: 24px;
}

.dropitems {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 400px;
}

.dropitems::-webkit-scrollbar {
  display: none;
}

button.flex.gap-2.justify-between.items-center.p-2.rounded-moon-i-sm.text-moon-14.focus\:outline-none.focus\:shadow-focus.cursor-pointer.hover\:bg-bulma\/\[0\.04\].transition.w-full.bg-primary.text-white:hover {
  background: #249cba !important;
}

/* 
.submitbox {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 480px;
} */

.acrdianbtn {
  padding-left: 0 !important;
}

.admindrawer {
  z-index: 1;
}

.forapprovel {
  height: 540px;
}

.sectionsvg {
  min-height:calc(100vh - 232px);
}

hr {
  border-color: #ebebeb;
}

.cstHeight {
  height: calc(100vh - 490px);
}

.empl-calendar {
  height: calc(100vh - 577px);
}

.cstScroll {
  overflow-y: inherit !important;
}

.imgAvatarRounded .overflow-hidden {
  border-radius: 50%;
}

.searchicon {
  position: absolute;
  top: 8px;
  left: 16px;
  z-index: 10;
}

.emloyeserch {
  position: absolute;
  top: 8px;
  left: 10px;
  z-index: 0;
}

.emloyeserchicon {
  position: absolute;
  top: 8px;
  left: 10px;
  z-index: 10;
}

.disable {
  opacity: 0.3;
  pointer-events: none;
}

@layer components {
  .avatar-xs {
    @apply inline-flex items-center justify-center w-[20px] h-[20px] rounded-full text-moon-9 bg-blue-600 text-white;
  }

  .monthCalendar
    .react-datepicker__input-container
    .react-datepicker__calendar-icon {
    @apply p-0 top-1/2 -translate-y-1/2 left-[8px] hidden;
  }

  .monthCalendar .react-datepicker__view-calendar-icon input {
    @apply px-3 text-moon-14 border-[1px] mx-0 focus:outline-hit;
  }

  .monthCalendar .react-datepicker__tab-loop {
    @apply absolute right-0 left-0 top-0;
  }

  .monthCalendar .react-datepicker {
    @apply p-1 my-2 rounded-moon-s-md box-border bg-white border-none shadow-moon-lg focus:outline-none font-averta;
  }

  .monthCalendar .react-datepicker-year-header {
    @apply border-none bg-sky1 font-averta;
  }

  .monthCalendar .react-datepicker__navigation {
    @apply border-none top-3 p-0 w-[24px] h-[24px];
  }

  .monthCalendar .react-datepicker__month .react-datepicker__month-text {
    @apply p-2;
  }

  .monthCalendar .react-datepicker__navigation-icon--previous {
    @apply right-0;
  }

  .monthCalendar .react-datepicker__header {
    @apply border-none bg-sky1;
  }

  .monthCalendar .react-datepicker__navigation-icon--next {
    @apply left-0;
  }

  .monthCalendar .react-datepicker__navigation-icon {
    @apply text-sm;
  }

  .monthCalendar .react-datepicker__day-name {
    @apply font-semibold font-averta;
  }

  .monthCalendar .react-datepicker__month-text--keyboard-selected {
    @apply bg-hit text-white;
  }

  .monthCalendar
    .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    @apply border-transparent;
  }

  .monthCalendar
    .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    @apply border-b-sky1;
  }

  .monthCalendar .react-datepicker__header {
    @apply rounded-moon-s-md;
  }

  .monthCalendar .react-datepicker__day--selected {
    @apply bg-hit;
  }

  .monthCalendar .react-datepicker__day:hover,
  .monthCalendar .react-datepicker__month-text:hover,
  .monthCalendar .react-datepicker__quarter-text:hover,
  .monthCalendar .react-datepicker__year-text:hover {
    @apply bg-sky1 text-hit;
  }
}

.bg-btn .bg-piccolo {
  background: rgba(36 156 186);
}

.relative.z-10 {
  z-index: 11;
}

.simplebarcard {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 518px);
}

.simplebarcard::-webkit-scrollbar {
  display: none;
}

#headlessui-portal-root .z-50 {
  z-index: 0;
}

.montlistView {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 419px;
}

.montlistView::-webkit-scrollbar {
  width: 5px;
}

.montlistView::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the thumb (the draggable part) */
  border-radius: 24px;
}

input.prefrenceInput {
  max-width: 57px;
  height: 40px;
  border-radius: 8px;
  text-align: center;
}

button[aria-expanded="false"] svg.cst-accordian {
  transform: rotate(0deg) !important;
}

button[aria-expanded="true"] svg.cst-accordian {
  transform: rotate(180deg) !important;
  /* !important; */
}

.table_height {
  overflow-x: hidden;
  /* overflow-y: scroll; */
  height: calc(100dvh - 470px);
}

/* ---------------responsive---------------- */
@media only screen and (max-width: 1556px) {
  .tableResponsive tr th,
  .tableResponsive tr td{
    @apply text-sm
  }
  input.prefrenceInput{
    height: 36px;
    max-width: 40px;
    font-size: 14px;
  }

  input{
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
}

.scheduler .simplebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.react-datepicker-wrapper{
  display:block;
}

.fixed[aria-modal="true"] {
  z-index: 1 !important;
}


.roundedleft-group select{
  @apply rounded-tl-none rounded-bl-none
}


.roundedleft-group select{
border-top-left-radius: 0px !important;
border-bottom-left-radius: 0px !important;
}


.uploadFile + label{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.selected-error {
  @apply border border-red-500
}

.react-datepicker-popper{
  z-index: 9999 !important;
}